import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const BookPage = ({ location }) => {
	const { language } = useI18next();
	const { t } = useTranslation('index');

	useEffect(() => {
		if (!window.cbk) {
			window.cbk = function () {
				window.cbk.p.push(arguments);
			};
		}
		window.cbk.p = [];

		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://cliento.com/widget-v2/cliento.js';

		document.body.appendChild(script);
		window.cbk('id', '3YZV6LAUUJqYNU7rtgTpaX');
		window.cbk('locale', language == 'sv' ? 'sv' : 'en');

		return () => {
			document.body.removeChild(script);
			delete window.cbk;
		};
	}, []);

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10">
						<div id="cliento-booking"></div>
					</div>
				</div>
			</div>
		</Framework>
	);
};

BookPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(BookPage);

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					data
					language
					ns
				}
			}
		}
	}
`;
